import React from "react";
import classes from "./LeaveSatisfied.module.scss";

import { ReactComponent as CompletedProjects } from "../../../assets/icons/check.svg";
import { ReactComponent as CoffeeDrinks } from "../../../assets/icons/chocolate.svg";
import { ReactComponent as HappyClients } from "../../../assets/icons/rating.svg";

import AOS from "aos";
import "aos/dist/aos.css";

import { Link } from "react-scroll";

const LeaveSatisfied = () => {
  AOS.init();
  return (
    <div className={classes.leaveSatisfied}>
      <div className={classes.content}>
        {/* //TODO: COMPLETED PROJECTS */}
        <div
          className={classes.containerBorder}
          data-aos="fade-up"
          data-aos-delay="000"
          data-aos-duration="1200"
        >
          <div className={classes.container}>
            <div className={classes.container__image}>
              <CompletedProjects className={classes.container__icon} />
            </div>
            <div className={classes.container__amount}>
              <span>60+</span>
            </div>
            <div className={classes.container__title}>
              <span>Completed Projects</span>
            </div>
          </div>
        </div>

        {/* //TODO: COFFEE DRINKS */}
        <div
          className={classes.containerBorder}
          data-aos="fade-up"
          data-aos-delay="300"
          data-aos-duration="1200"
        >
          <div className={classes.container}>
            <div className={classes.container__image}>
              <CoffeeDrinks className={classes.container__icon} />
            </div>
            <div className={classes.container__amount}>
              <span>600+</span>
            </div>
            <div className={classes.container__title}>
              <span>Coffee Drinks</span>
            </div>
          </div>
        </div>

        {/* //TODO: HAPPY CLIENTS */}
        <div
          className={classes.containerBorder}
          data-aos="fade-up"
          data-aos-delay="600"
          data-aos-duration="1200"
        >
          <div className={classes.container}>
            <div className={classes.container__image}>
              <HappyClients className={classes.container__icon} />
            </div>
            <div className={classes.container__amount}>
              <span>100+</span>
            </div>
            <div className={classes.container__title}>
              <span>Happy Clients</span>
            </div>
          </div>
        </div>
      </div>

      <h3
        className={classes.slogan}
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-duration="1200"
        data-aos-offset="-25"
      >
        {" "}
        Always Leave Satisfied
      </h3>
      <div
        className={classes.buttonContainer}
        data-aos="fade"
        data-aos-delay="750"
        data-aos-duration="2000"
        data-aos-offset="-25"
      >
        <div className={classes.buttonContact}>
          <div className={classes.buttonContact__border}>
            <div className={classes.buttonContact__container}>
              <Link
                to="contact"
                smooth={true}
                spy={true}
                duration={1000}
                offset={0}
                className={classes.buttonContact__contact}
              >
                <span>Contact</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaveSatisfied;
