import React from "react";

import WhoWeAre from "./WhoWeAre/WhoWeAre";
// import TheTeam from "./TheTeam/TheTeam";

const AboutUs = () => {
  return (
    <div id="about">
      <WhoWeAre />
      {/* <TheTeam /> */}
    </div>
  );
};

export default AboutUs;
