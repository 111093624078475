import React from "react";

import classes from "./Footer.module.scss";

import { ReactComponent as FbIcon } from "../../assets/icons/facebookFooter.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/icons/youtubeFooter.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/instagramFooter.svg";
import { ReactComponent as TwitterIcon } from "../../assets/icons/twitterFooter.svg";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const facebookLink = "https://www.facebook.com/techpolAu";
  //const youtubeLink = "https://www.youtube.com";
  const instagramLink = "https://www.instagram.com/techpol_au/";
  const twitterLink = "https://www.twitter.com/techpol6";

  return (
    <div className={classes.footer}>
      <div className={classes.content}>
        <div className={classes.content__copyright}>
          {currentYear} &copy; Techpol • Aluminium Machinery Servicing
        </div>
        <div className={classes.content__socials}>
          <a href={facebookLink} target="_blank" rel="noopener noreferrer">
            <FbIcon className={classes.icon} />
          </a>
          {/* <a href={youtubeLink} target="_blank" rel="noopener noreferrer">
            <YoutubeIcon className={classes.icon} />
          </a> */}
          <a href={instagramLink} target="_blank" rel="noopener noreferrer">
            <InstagramIcon className={classes.icon} />
          </a>
          <a href={twitterLink} target="_blank" rel="noopener noreferrer">
            <TwitterIcon className={classes.icon} />
          </a>
        </div>
        <div className={classes.content__developed}>
         <a href="https://patrickminda.netlify.app/" target="_blank" rel="noopener noreferrer">
           Developed and designed by Patrick Minda 
        </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
