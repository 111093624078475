import React from "react";
import classes from "./WhoWeAre.module.scss";

import { ReactComponent as XpIcon } from "../../../assets/icons/30yrXP.svg";
import { ReactComponent as SupportIcon } from "../../../assets/icons/training.svg";
import { ReactComponent as IndustryXPIcon } from "../../../assets/icons/AlumXP.svg";
import { ReactComponent as RepairIcon } from "../../../assets/icons/ServiceWWA.svg";

import AOS from "aos";
import "aos/dist/aos.css";

const WhoWeAre = () => {
  AOS.init();
  return (
    <div className={classes.whoWeAre}>
      <div className={classes.grid}>
        <h1 className={classes.grid__heading}>
          <span>About Us</span>
        </h1>
        <h2
          className={classes.grid__title}
          data-aos="fade-right"
          data-aos-delay="000"
          data-aos-duration="1200"
        >
          <span>Who We Are</span>
        </h2>
        <p
          className={classes.grid__description}
          data-aos="fade-right"
          data-aos-delay="200"
          data-aos-duration="1200"
          data-aos-offset="-50"
        >
          Techpol is a Sydney based engineering services company specializing in
          machines for extruded aluminium and PVC. We primarily work with the
          windows and doors industry that utilize aluminium machinery.
        </p>
        <div className={classes.grid__points}>
          {/* //TODO: 30 Years Experience*/}
          <div className={classes.pointLeft}>
            <div
              className={classes.pointLeft__imgContainer}
              data-aos="fade"
              data-aos-delay="1350"
              data-aos-duration="1000"
            >
              <XpIcon className={classes.icon} />
            </div>
            <div
              className={classes.pointLeft__titleContainer}
              data-aos="fade-right"
              data-aos-delay="500"
              data-aos-duration="1200"
            >
              <h3>30 Years Experience</h3>
            </div>
            <div
              className={classes.pointLeft__descriptionContainer}
              data-aos="fade-right"
              data-aos-delay="500"
              data-aos-duration="1200"
            >
              <p>
                Our decades of experience allow us to deliver a comprehensive
                range of services that cover every aspect of repairs and
                maintenance of industrial machines.
              </p>
            </div>
          </div>
          {/* //TODO: Aluminium Industry Experience */}
          <div className={classes.pointRight}>
            <div
              className={classes.pointRight__imgContainer}
              data-aos="fade"
              data-aos-delay="1350"
              data-aos-duration="1000"
            >
              <IndustryXPIcon className={classes.icon} />
            </div>
            <div
              className={classes.pointRight__titleContainer}
              data-aos="fade-left"
              data-aos-delay="250"
              data-aos-duration="1200"
            >
              <h3>Aluminium Industry Experience</h3>
            </div>
            <div
              className={classes.pointRight__descriptionContainer}
              data-aos="fade-left"
              data-aos-delay="250"
              data-aos-duration="1200"
            >
              <p>
                We’ve been servicing in the aluminium industry since early 2008.
                Our solid reputation in the industry is not only based on our
                expertise but also our fair dealings and integrity in terms of
                customer care and value-for-money solutions.
              </p>
            </div>
          </div>
          {/* //TODO: Service / Repair*/}
          <div className={classes.pointLeft}>
            <div
              className={classes.pointLeft__imgContainer}
              data-aos="fade"
              data-aos-delay="1350"
              data-aos-duration="1000"
            >
              <RepairIcon className={classes.icon} />
            </div>
            <div
              className={classes.pointLeft__titleContainer}
              data-aos="fade-right"
              data-aos-delay="250"
              data-aos-duration="1200"
            >
              <h3>Service & Repair</h3>
            </div>
            <div
              className={classes.pointLeft__descriptionContainer}
              data-aos="fade-right"
              data-aos-delay="250"
              data-aos-duration="1200"
            >
              <p>
                Techpol can handle your servicing and repair, from scheduled
                maintenance to specialised work that is executed high focus,
                quality, and efficiency.
              </p>
            </div>
          </div>
          {/* //TODO: Support & Training */}
          <div className={`${classes.pointRight} ${classes.supportPoint}`}>
            <div
              className={classes.pointRight__imgContainer}
              data-aos="fade"
              data-aos-delay="1350"
              data-aos-duration="1000"
            >
              <SupportIcon className={classes.icon} />
            </div>
            <div
              className={classes.pointRight__titleContainer}
              data-aos="fade-left"
              data-aos-delay="250"
              data-aos-duration="1200"
            >
              <h3>Support & Training</h3>
            </div>
            <div
              className={classes.pointRight__descriptionContainer}
              data-aos="fade-left"
              data-aos-delay="250"
              data-aos-duration="1200"
            >
              <p>
                We provide operator training and support for machines. Some
                support can be done online or over the phone after
                troubleshooting your equipment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoWeAre;
