import React from "react";
import classes from "./WhatWeDo.module.scss";

import { ReactComponent as RepairIcon } from "../../../assets/icons/admin.svg";
import { ReactComponent as TroubleshootIcon } from "../../../assets/icons/pencil.svg";
import { ReactComponent as CommissionIcon } from "../../../assets/icons/gears.svg";
import { ReactComponent as TagIcon } from "../../../assets/icons/Tag.svg";
import cncMachine from "../../../assets/images/cncPic_001.jpg";

import AOS from "aos";
import "aos/dist/aos.css";

const WhatWeDo = () => {
  AOS.init();
  return (
    <div className={classes.whatWeDo}>
      <div className={classes.grid}>
        <h1 className={classes.grid__heading}>
          <span>Our Services</span>
        </h1>
        <h2
          className={classes.grid__title}
          data-aos="fade-right"
          data-aos-delay="000"
          data-aos-duration="1200"
        >
          <span>What We Do</span>
        </h2>
        <p
          className={classes.grid__description}
          data-aos="fade-right"
          data-aos-delay="200"
          data-aos-duration="1200"
        >
          At Techpol, we specialize in machines for the production of windows,
          doors, and curtain walls. (Aluminium and PVC) Machines for processing
          of extruded industrial aluminium.
        </p>
        <div
          className={classes.grid__image}
          data-aos="fade"
          data-aos-delay="200"
          data-aos-duration="2000"
        >
          <img src={cncMachine} alt="CNC Machine" />
        </div>
        <div className={classes.grid__points}>
          {/* //TODO: Repair / Service */}
          <div
            className={`${classes.point} ${classes.point__1}`}
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-duration="1000"
          >
            <TroubleshootIcon className={classes.point__icon} />

            <h3 className={classes.point__title}>
              {" "}
              Troubleshooting & Consulting
            </h3>
            <p className={classes.point__description}>
              We Assess customer requirements and specifications before
              purchasing a new machine. We provide Operator training and also
              troubleshooting over the phone or online.
            </p>
          </div>

          {/* //TODO: Commissioning and Decommissioning */}
          <div
            className={`${classes.point} ${classes.point__2}`}
            data-aos="fade-up"
            data-aos-delay="500"
            data-aos-duration="1000"
          >
            <CommissionIcon className={classes.point__icon} />
            <h3 className={classes.point__title}>
              Commissioning & Decommissioning
            </h3>
            <p className={classes.point__description}>
              We provide the installation of new machines or the uninstallation
              of old machines.
              <br />
              We prepare machines for transportation and rebuild machines at
              relocated sites.
            </p>
          </div>

          {/* //TODO: Troubleshooting and Consulting */}
          <div
            className={`${classes.point} ${classes.point__3}`}
            data-aos="fade-up"
            data-aos-delay="800"
            data-aos-duration="1000"
          >
            <RepairIcon className={classes.point__icon} />

            <h3 className={classes.point__title}>Repair & Service</h3>
            <p className={classes.point__description}>
              We also provide repairs and servicing on a wide variety of
              machines from basic saws to CNC machines.
            </p>
          </div>

          {/* //TODO: Test & Tag */}
          <div
            className={`${classes.point} ${classes.point__4}`}
            data-aos="fade-up"
            data-aos-delay="1100"
            data-aos-duration="1000"
          >
            <TagIcon className={classes.point__icon} />

            <h3 className={classes.point__title}>Test & Tag</h3>
            <p className={classes.point__description}>
              We provide on-site inspection and electrical testing of equipment
              and appliances to Australian Standard AS/NZS 3760 (1 and 3 phase
              power). This is complimentary with any service or repair if
              necessary.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
