import React from "react";

import WhatWeDo from "./WhatWeDo/WhatWeDo";
import HowWeDoIt from "./HowWeDoIt/HowWeDoIt";
import LeaveSatisfied from "./LeaveSatisfied/LeaveSatisfied";

const Services = () => {
  return (
    <div id="services">
      <WhatWeDo />
      <HowWeDoIt />
      <LeaveSatisfied />
    </div>
  );
};

export default Services;
