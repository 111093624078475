import React from "react";

import classes from "./Hero.module.scss";

import { Link } from "react-scroll";
import AOS from "aos";
import "aos/dist/aos.css";

const Hero = () => {
  AOS.init();

  return (
    <div className={classes.hero} id="hero">
      <div className={classes.content}>
        {/* <TechpolLogo className={classes.content__logo} /> */}
        <h1
          className={classes.content__title}
          data-aos="fade"
          data-aos-delay="000"
          data-aos-duration="1200"
        >
          Tech<span>pol</span>
        </h1>
        <h3
          className={classes.content__slogan}
          data-aos="fade"
          data-aos-delay="1000"
          data-aos-duration="1750"
        >
          A service experience you deserve
        </h3>
        <p
          className={classes.content__description}
          data-aos="fade-up"
          data-aos-delay="1500"
          data-aos-duration="2000"
        >
          Swift and effective solutions for all types of aluminium machinery.
        </p>
        <div
          className={classes.buttonContact}
          data-aos="fade-up"
          data-aos-delay="2000"
          data-aos-duration="1000"
        >
          <div className={classes.buttonContact__border}>
            <div className={classes.buttonContact__container}>
              <Link
                to="contact"
                smooth={true}
                spy={true}
                duration={1500}
                offset={0}
                className={classes.buttonContact__contact}
              >
                <span>Contact</span>
              </Link>
            </div>
          </div>
        </div>
        <div
          className={classes.buttonServices}
          data-aos="fade-up"
          data-aos-delay="2000"
          data-aos-duration="1000"
        >
          <div className={classes.buttonServices__border}>
            <div className={classes.buttonServices__container}>
              <Link
                to="services"
                smooth={true}
                spy={true}
                duration={1000}
                offset={0}
                className={classes.buttonServices__services}
              >
                <span>Services</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
