import React from "react";
import classes from "./HowWeDoIt.module.scss";

// import { ReactComponent as Step1Img } from "../../../assets/icons/step-1.svg";
// import { ReactComponent as Step2Img } from "../../../assets/icons/step-2.svg";
// import { ReactComponent as Step3Img } from "../../../assets/icons/step-3.svg";

import { ReactComponent as Step1Img } from "../../../assets/icons/step-1_colored.svg";
import { ReactComponent as Step2Img } from "../../../assets/icons/step-2_colored.svg";
import { ReactComponent as Step3Img } from "../../../assets/icons/step-3_colored.svg";

import { ReactComponent as LikeIcon } from "../../../assets/icons/like.svg";

import AOS from "aos";
import "aos/dist/aos.css";

const HowWeDoIt = () => {
  AOS.init();
  return (
    <div className={classes.howWeDoIt}>
      <div className={classes.grid}>
        <h2
          className={classes.grid__title}
          data-aos="fade-right"
          data-aos-delay="000"
          data-aos-duration="1200"
        >
          <span>How We Do It</span>
        </h2>
        <p
          className={classes.grid__description}
          data-aos="fade-right"
          data-aos-delay="200"
          data-aos-duration="1200"
        >
          A professionally done job from start to finish.
        </p>
        <div className={classes.steps}>
          {/* //TODO: STEP 1 */}
          <div
            className={classes.steps__imageContainer1}
            data-aos="fade-right"
            data-aos-delay="000"
            data-aos-duration="1200"
          >
            <Step1Img className={classes.img} />
          </div>
          <div
            className={classes.steps__step1}
            data-aos="fade-left"
            data-aos-delay="000"
            data-aos-duration="1200"
          >
            <p className={classes.stepText}>
              Techpol troubleshoots and inspects machines onsite. Sometimes
              problems may be fixed over the phone which can resolve the issue
              much quicker. Identifying the correct machine over the phone and
              describing the problem can reduce costs and visits.
            </p>
          </div>
          {/* //TODO: STEP 2 */}
          <div
            className={classes.steps__imageContainer2}
            data-aos="fade-left"
            data-aos-delay="000"
            data-aos-duration="1200"
          >
            <Step2Img className={classes.img} />
          </div>
          <div
            className={classes.steps__step2}
            data-aos="fade-right"
            data-aos-delay="000"
            data-aos-duration="1200"
          >
            <p className={classes.stepText}>
              We arrange for a flexible and punctual appointment which allows
              for minimal interference with production. At Techpol, we are
              committing to providing prompt and effective service. After the
              inspection, we build a final analysis of the machine and pinpoint
              possible future issues that may arise. This allows us to plan and
              fix these arising issues in which we can organize a solution.
            </p>
          </div>
          {/* //TODO: STEP 3 */}
          <div
            className={classes.steps__imageContainer3}
            data-aos="fade-right"
            data-aos-delay="000"
            data-aos-duration="1200"
          >
            <Step3Img className={classes.img} />
          </div>
          <div
            className={classes.steps__step3}
            data-aos="fade-left"
            data-aos-delay="000"
            data-aos-duration="1200"
          >
            <p className={classes.stepText}>
              The Machine will be tested in presence of operator or superviser
              before leaving the site. Complimentary test and tag service is
              available when required. Arrangements for future visits may be
              made to resolve arising issues.
              <br />
              <span
                data-aos="fade-up"
                data-aos-delay="750"
                data-aos-duration="1200"
              >
                <LikeIcon className={classes.likeIcon} />
                That's It!
              </span>
              <br />
              Rate your experience and leave a review.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowWeDoIt;
