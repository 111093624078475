import React from "react";
import "./App.scss";

import Navigation from "./containers/Navigation/Navigation";
import Hero from "./containers/Hero/Hero";
import Services from "./containers/Services/Services";
import AboutUs from "./containers/AboutUs/AboutUs";
import OurClients from "./containers/OurClients/OurClients";
import ContactUs from "./containers/ContactUs/ContactUs";
import Footer from "./containers/Footer/Footer";

function App() {
  return (
    <div className="App">
      <Navigation />
      <Hero />
      <Services />
      <AboutUs />
      <OurClients />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
