import React from "react";
import classes from "./Toolbar.module.scss";

import { ReactComponent as LogoSVG } from "../../assets/logo/LogoLight.svg";
import logo from '../../assets/logo/LogoLight.svg';

import { Link } from "react-scroll";

const Toolbar = (props) => {
  const overlayHandler = () => {
    console.log("overlay handler");
    props.setOpen(false);
  };

  const navHandler = () => {
    console.log("nav handler");
    props.setOpen(false);
  };

  return (
    <div>
      <div
        className={`${classes.overlay} ${props.open ? null : classes.remove}`}
        onClick={() => overlayHandler()}
      ></div>
      {/*  ${classes.remove} */}
      <div
        className={`${classes.toolbar} ${
          props.open ? classes.open : classes.close
        }`}
      >
        <div className={classes.logoContainer}>
          <img src={logo} className={classes.logoToolbar} alt="Techpol logo"/>
          {/* <LogoSVG className={classes.logoToolbar} /> */}
        </div>

        <ul className={classes.navList}>
          <li className={classes.navItem}>
            <Link
              to="services"
              smooth={true}
              spy={true}
              duration={1750}
              offset={0}
              className={classes.navLink}
              onClick={() => navHandler()}
            >
              <span>Services</span>
            </Link>
          </li>
          <li className={classes.navItem}>
            <Link
              to="about"
              smooth={true}
              spy={true}
              duration={2000}
              offset={0}
              className={classes.navLink}
              onClick={() => navHandler()}
            >
              <span>About</span>
            </Link>
          </li>
          <li className={classes.navItem}>
            <Link
              to="clients"
              smooth={true}
              spy={true}
              duration={2200}
              offset={0}
              className={classes.navLink}
              onClick={() => navHandler()}
            >
              <span>Clients</span>
            </Link>
          </li>
          <li className={classes.navItem}>
            <Link
              to="contact"
              smooth={true}
              spy={true}
              duration={2200}
              offset={0}
              className={classes.navLink}
              onClick={() => navHandler()}
            >
              <span>Contact</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Toolbar;
