import React, {useState} from "react";

import classes from "./ContactUs.module.scss";

import { ReactComponent as UpIcon } from "../../assets/icons/up-arrow.svg";
import ContactSVG from "./ContactSVG";

import { Link } from "react-scroll";

import AOS from "aos";
import "aos/dist/aos.css";

import emailjs from 'emailjs-com';

const ContactUs = () => {

  AOS.init();


  const [emailSuccess, setEmailSuccess] = useState('none');

  const [name, setName] = useState();

  const [phone, setPhone] = useState();

  const [email, setEmail] = useState();

  const [message, setMessage] = useState();

  function sendEmail(e) {
    e.preventDefault();
//"techpol_email",
    emailjs
      .sendForm(
        "techpol_email",
        "techpol_email_template",
        e.target,
        "user_SI2D6EsOVQ3hJLkliecPO"
      )
      .then(
        (result) => {
          console.log("APPARRENTLY EMAIL SENT SUCESSFULLY!")
          console.log(result);
          console.log(result.text);
          setEmailSuccess('yes');
          setName("");
          setEmail("");
          setMessage("");
          setPhone("");
        },
        (error) => {
          console.log("EMAIL NOT SENT!")
          console.log(error.text);
          setEmailSuccess('no');
        }
      );
  }


  return (
    <div className={classes.contactUs} id="contact">
      <div className={classes.contactContainer}>
        <div className={classes.overlay}>
          <div className={classes.grid}>
            <h1 className={classes.grid__heading}>
              <span>Contact Us</span>
            </h1>
            <p
              className={classes.grid__description}
              data-aos="fade-up"
              data-aos-delay="000"
              data-aos-duration="1200"
            >
              We are based in the Sydney metro area, but we are not shy to visit
              customers all over NSW.
            </p>
            <h2
              className={classes.grid__getInTouch}
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="1200"
            >
              Send an enquiry below
            </h2>
            <form
              className={classes.formContainer}
              data-aos="fade-up"
              data-aos-delay="500"
              data-aos-duration="1200"
              data-aos-offset="-100"
              onSubmit={sendEmail}
            >

              {emailSuccess === "no" ? <span className={classes.error}>Email was unable to send...please try again</span> : null }
              {emailSuccess === "yes" ? <span className={classes.success}>Email was successfully sent. We will be in contact shortly.</span> : null }

              <div
                className={`${classes.borderContainer} ${classes.nameInputContainer}`}
              >
                <input
                  type="text"
                  placeholder="Name"
                  value={name}
                  className={classes.nameInput}
                  onChange={(e) => setName(e.target.value)}
                  name="user_name"
                />
              </div>
              <div
                className={`${classes.borderContainer} ${classes.phoneInputContainer}`}
              >
                <input
                  type="text"
                  placeholder="Phone"
                  value={phone}
                  className={classes.phoneInput}
                  onChange={(e) => setPhone(e.target.value)}
                  name="user_phone"
                />
              </div>
              <div
                className={`${classes.borderContainer} ${classes.emailInputContainer}`}
              >
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  className={classes.emailInput}
                  onChange={(e) => setEmail(e.target.value)}
                  name="user_email"
                />
              </div>
              <div
                className={`${classes.borderFullContainer} ${classes.messageInputContainer}`}
              >
                <textarea
                  name="user_message"
                  placeholder="Message"
                  value={message}
                  className={classes.messageInput}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
              <div
                className={`${classes.borderFullContainer} ${classes.borderFullContainer__sendButton}`}
              >
                <input type="submit" className={classes.sendButton} value="Send Message"/>
              </div>
            </form>
            
          </div>
        </div>
        <div className={classes.rightContainer}>
          <div
            className={classes.contactSVGContainer}
            data-aos="fade-left"
            data-aos-delay="300"
            data-aos-duration="1200"
          >
            {/* <TestIcon className={classes.contactSVG} /> */}
            <ContactSVG className={classes.contactSVG} />
            {/* <img src={ContactPNG} alt="test" /> */}
          </div>
        </div>
      </div>
      <div className={classes.topButton}>
        <div className={classes.arrowContainer}>
          <Link
            to="hero"
            smooth={true}
            spy={true}
            duration={1000}
            offset={0}
            className={classes.upArrow}
          >
            <UpIcon className={classes.upArrow} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
