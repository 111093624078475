import React, { useState } from "react";

import classes from "./Navigation.module.scss";

import { ReactComponent as LogoSVG } from "../../assets/logo/LogoLight.svg";
import { ReactComponent as LogoTextSVG } from "../../assets/logo/TechpolText.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-scroll";

import Toolbar from "./Toolbar";

const Navigation = () => {
  const [open, setOpen] = useState(false);

  const toolbarHandler = () => {
    setOpen(true);
  };

  return (
    <div>
      <nav className={classes.navigation}>
        <div className={classes.logoContainer}>
          <LogoSVG className={classes.logoIcon} />
          <LogoTextSVG className={classes.logoText} />
        </div>
        <ul className={classes.navList}>
          <li className={classes.navItem}>
            <Link
              to="services"
              smooth={true}
              spy={true}
              duration={1750}
              offset={0}
              className={classes.navLink}
            >
              <span>Services</span>
            </Link>
          </li>
          <li className={classes.navItem}>
            <Link
              to="about"
              smooth={true}
              spy={true}
              duration={2000}
              offset={0}
              className={classes.navLink}
            >
              <span>About</span>
            </Link>
          </li>
          <li className={classes.navItem}>
            <Link
              to="clients"
              smooth={true}
              spy={true}
              duration={2200}
              offset={0}
              className={classes.navLink}
            >
              <span>Clients</span>
            </Link>
          </li>
          <li className={classes.navItem}>
            <Link
              to="contact"
              smooth={true}
              spy={true}
              duration={2200}
              offset={0}
              className={classes.navLink}
            >
              <span>Contact</span>
            </Link>
          </li>
        </ul>
      </nav>

      <nav className={classes.navigationMob}>
        <FontAwesomeIcon
          icon={faBars}
          className={classes.toggleBtn}
          onClick={() => toolbarHandler()}
        />

        <Toolbar open={open} setOpen={setOpen} />
      </nav>
    </div>
  );
};

export default Navigation;
