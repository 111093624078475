import React from "react";
import { Link } from "react-scroll";

import classes from "./OurClients.module.scss";

import company1 from "../../assets/client_logos/client_001.png";
import company2 from "../../assets/client_logos/client_002.png";
import company3 from "../../assets/client_logos/client_003.png";
import company4 from "../../assets/client_logos/client_004.png";
import company5 from "../../assets/client_logos/client_005.png";
import company6 from "../../assets/client_logos/client_006.png";
import company7 from "../../assets/client_logos/client_007.png";
import company8 from "../../assets/client_logos/client_008.jpg";
import company9 from "../../assets/client_logos/client_009.png";

import AOS from "aos";
import "aos/dist/aos.css";

const OurClients = () => {
  AOS.init();

  return (
    <div className={classes.ourClients} id="clients">
      <div className={classes.pageGrid}>
        <h1 className={classes.pageGrid__heading}>
          <span>Our Clients</span>
        </h1>
        <div
          className={classes.pageGrid__description}
          data-aos="fade-up"
          data-aos-delay="000"
          data-aos-duration="1000"
        >
          <h2>Some of the companies that we have successfully helped.</h2>
        </div>

        <div className={classes.marquee}>
          <div className={classes.tracks}>
            <div className={classes.track1}>
              <div className={classes.logoContainer}>
                <img
                  src={company1}
                  alt="Company Logo"
                  className={classes.logo}
                />
              </div>
              <div className={classes.logoContainer}>
                <img
                  src={company2}
                  alt="Company Logo"
                  className={classes.logo}
                />
              </div>
              <div className={classes.logoContainer}>
                <img
                  src={company3}
                  alt="Company Logo"
                  className={classes.logo}
                />
              </div>
              <div className={classes.logoContainer}>
                <img
                  src={company4}
                  alt="Company Logo"
                  className={classes.logo}
                />
              </div>
              <div className={classes.logoContainer}>
                <img
                  src={company5}
                  alt="Company Logo"
                  className={classes.logo}
                />
              </div>
              <div className={classes.logoContainer}>
                <img
                  src={company6}
                  alt="Company Logo"
                  className={classes.logo}
                />
              </div>
              <div className={classes.logoContainer}>
                <img
                  src={company7}
                  alt="Company Logo"
                  className={classes.logo}
                />
              </div>
              <div className={classes.logoContainer}>
                <img
                  src={company8}
                  alt="Company Logo"
                  className={classes.logo}
                />
              </div>
              <div className={classes.logoContainer}>
                <img
                  src={company9}
                  alt="Company Logo"
                  className={classes.logo}
                />
              </div>
            </div>
            <div className={classes.track2}>
              <div className={classes.logoContainer}>
                <img
                  src={company1}
                  alt="Company Logo"
                  className={classes.logo}
                />
              </div>
              <div className={classes.logoContainer}>
                <img
                  src={company2}
                  alt="Company Logo"
                  className={classes.logo}
                />
              </div>
              <div className={classes.logoContainer}>
                <img
                  src={company3}
                  alt="Company Logo"
                  className={classes.logo}
                />
              </div>
              <div className={classes.logoContainer}>
                <img
                  src={company4}
                  alt="Company Logo"
                  className={classes.logo}
                />
              </div>
              <div className={classes.logoContainer}>
                <img
                  src={company5}
                  alt="Company Logo"
                  className={classes.logo}
                />
              </div>
              <div className={classes.logoContainer}>
                <img
                  src={company6}
                  alt="Company Logo"
                  className={classes.logo}
                />
              </div>
              <div className={classes.logoContainer}>
                <img
                  src={company7}
                  alt="Company Logo"
                  className={classes.logo}
                />
              </div>
              <div className={classes.logoContainer}>
                <img
                  src={company8}
                  alt="Company Logo"
                  className={classes.logo}
                />
              </div>
              <div className={classes.logoContainer}>
                <img
                  src={company9}
                  alt="Company Logo"
                  className={classes.logo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={classes.pageGrid__enquire}
        data-aos="fade"
        data-aos-delay="250"
        data-aos-duration="1200"
      >
        <h3>
          <Link
            to="contact"
            smooth={true}
            spy={true}
            duration={500}
            offset={0}
            className={classes.contactLink}
          >
            Contact us
          </Link>{" "}
          with your enquiry so we make find a solution for you.
        </h3>
      </div>
    </div>
  );
};

export default OurClients;
